import { defActionTypes } from '../../utils/defActionTypes';
import { defAction } from '../../utils/defAction';

export const authActions = defActionTypes('auth');
export const userActions = defActionTypes('user');
export const logoutActions = defActionTypes('logout');
export const resetPasswordActions = defActionTypes('resetPassword');
export const loadInviteLocationActions = defActionTypes('load_invite_location');
export const authByInviteActions = defActionTypes('auth_by_invite');
export const getLocationTokenActions = defActionTypes('get_location_token');
export const UNAUTHORIZED_USER = 'UNAUTHORIZED_USER';

export const checkForExistingUserActions = defActionTypes('check_for_existing_user');
export const setGuestAuthorizationMethod = defAction<'zipCode' | 'dob'>('set_guest_authorization_method');
