import { ILocation } from 'types/location';
import {
  getInviteLocationRequest,
} from '../apiRequests';
import { defThunkRequest } from '../../utils/defAsyncAction';
import {
  getCustomerInviteLocationActions,
} from './actionTypes';

export const getCustomerInviteLocationThunk = defThunkRequest<{invite: string}, ILocation>({
  actionTypes: getCustomerInviteLocationActions,
  thunkSteps: [
    ({ data }) => getInviteLocationRequest(data.invite),
  ],
});
