import { apiRequest } from '../../utils/apiRequest';
import { getInviteMode } from '../../utils/apiInviteKey';
import { toParams } from '../../utils/params';
import { UpdateUserInfo } from '../../types/user';
import { ILenderStatusExtended } from '../../types/otherData';

export const downloadFileRequest = ({
  id,
}: any) => apiRequest(`upload/${id}`, {
  method: 'GET',
});

export const confirmUploadRequest = (uploadId: string) => apiRequest(`upload/${uploadId}`, {
  method: 'POST',
});

export const getUserInfo = () => apiRequest('user/me', {
  method: 'get',
});

export const getApplicationVersion = () => apiRequest('version', {
  method: 'get',
  endpoint: '',
});

export const updateUserInfo = (body: UpdateUserInfo) => apiRequest('user/me', {
  method: 'POST',
  body,
});

export const logoutRequest = () => apiRequest('logout', {
  method: 'POST',
});

export type AuthByInviteBody = {
  zipCode: string;
} | {
  dob: string;
};

export const authByInviteRequest = (body: AuthByInviteBody) => apiRequest('auth/invite', {
  method: 'POST',
  body: {
    ...body,
    inviteKey: getInviteMode(),
  },
});

export const getLocationTokenRequest = (locationId: string) => apiRequest(`token/${locationId}`, {
  method: 'POST',
});

export type BiSupportReportsParams = {
  network?: string;
  createdFrom?: string | null;
  createdTo?: string | null;
};

export const getBISupportReportsRequest = (body: BiSupportReportsParams) => apiRequest(
  `reports/aggregate/submission${toParams({
    network: body.network,
    created_from: body.createdFrom,
    created_to: body.createdTo,
  })}`, {
    method: 'get',
  },
);

export const authCheckForExistingUser = () => apiRequest(
  'auth/login', {
    method: 'POST',
  },
);

export const getLendersStatusRequest = () => apiRequest(
  'settings/lenders', {
    method: 'get',
  },
);

export const setLenderStatusRequest = (body: ILenderStatusExtended) => apiRequest(
  'settings/lenders', {
    method: 'POST',
    body,
  },
);

export type RecoverBody = {
  start_time: string;
  end_time: string;
  lender_name: string;
  statuses_to_revert: Array<'error'|'timeout'|'config_error'|'validation_error'>;
  stages_to_revert: Array<'created'|'preapproved'|'approved'>;
};

export const recoverLenderFromCrashRequest = (body: RecoverBody) => apiRequest(
  'lender_application/recover', {
    method: 'POST',
    body,
  },
);

export const getSsoDomainsRequest = () => apiRequest(
  'sso', {
    method: 'GET',
  },
);

export const getCareCreditSessionToken = () => apiRequest(
  `carecredit/initiate${toParams({ invite: getInviteMode() })}`, {
    method: 'POST',
  },
);

export type ExtendCareCreditSessionProps = {
    zipCode: string;
    dob: string;
    ssn: string;
};
export const extendCareCreditSessionToken = (body: ExtendCareCreditSessionProps) => apiRequest(
  `carecredit/extend_session${toParams({ invite: getInviteMode() })}`, {
    method: 'POST',
    body,
  },
);
