/* eslint-disable max-len */
import React from 'react';
import { IconProps } from 'components/Icons/types';
import { BaseIcon } from 'components/Icons/BaseIcon';

export default function LogoUpdate32({
  position,
}: IconProps) {
  return (
    <BaseIcon
      sizeX={32}
      sizeY={32}
      color="none"
      position={position}
    >
      <rect x="2.00011" y="16.8598" width="19.9998" height="6.28014" fill="#4803FF" />
      <path
        d="M2.00042 23.14V16.8195C5.75041 16.8195 8.79038 13.7663 8.79038 9.99999H15.0835C15.0835 17.257 9.22601 23.14 2.00042 23.14Z"
        fill="url(#paint0_linear_1831_24)"
      />
      <path
        d="M22.0001 16.8598V23.1803C18.2501 23.1803 15.2102 26.2335 15.2102 29.9998H8.91702C8.91702 22.7428 14.7745 16.8598 22.0001 16.8598Z"
        fill="url(#paint1_linear_1831_24)"
      />
      <path
        d="M28.4779 6.42618C28.594 5.85794 29.406 5.85794 29.5221 6.42617L29.9695 8.61512C30.0122 8.82429 30.1757 8.98775 30.3849 9.0305L32.5738 9.47791C33.1421 9.59405 33.1421 10.406 32.5738 10.5221L30.3849 10.9695C30.1757 11.0122 30.0122 11.1757 29.9695 11.3849L29.5221 13.5738C29.406 14.1421 28.594 14.1421 28.4779 13.5738L28.0305 11.3849C27.9878 11.1757 27.8243 11.0122 27.6151 10.9695L25.4262 10.5221C24.8579 10.406 24.8579 9.59405 25.4262 9.47791L27.6151 9.0305C27.8243 8.98775 27.9878 8.82429 28.0305 8.61512L28.4779 6.42618Z"
        fill="#FD00C3"
      />
      <path
        d="M21.739 4.21309C21.797 3.92897 22.203 3.92897 22.261 4.21309L22.4847 5.30756C22.5061 5.41214 22.5879 5.49388 22.6924 5.51525L23.7869 5.73895C24.071 5.79702 24.071 6.20298 23.7869 6.26105L22.6924 6.48475C22.5879 6.50612 22.5061 6.58785 22.4847 6.69244L22.261 7.78691C22.203 8.07103 21.797 8.07103 21.739 7.78691L21.5153 6.69244C21.4939 6.58786 21.4121 6.50612 21.3076 6.48475L20.2131 6.26105C19.929 6.20298 19.929 5.79702 20.2131 5.73895L21.3076 5.51525C21.4121 5.49388 21.4939 5.41214 21.5153 5.30756L21.739 4.21309Z"
        fill="#FD00C3"
      />
      <path
        d="M27.3042 0.159816C27.3478 -0.0532717 27.6522 -0.053272 27.6958 0.159815L27.8636 0.980671C27.8796 1.05911 27.9409 1.12041 28.0193 1.13644L28.8402 1.30422C29.0533 1.34777 29.0533 1.65223 28.8402 1.69578L28.0193 1.86356C27.9409 1.87959 27.8796 1.94089 27.8636 2.01933L27.6958 2.84018C27.6522 3.05327 27.3478 3.05327 27.3042 2.84018L27.1364 2.01933C27.1204 1.94089 27.0591 1.87959 26.9807 1.86356L26.1598 1.69578C25.9467 1.65223 25.9467 1.34777 26.1598 1.30422L26.9807 1.13644C27.0591 1.12041 27.1204 1.05911 27.1364 0.980671L27.3042 0.159816Z"
        fill="#FD00C3"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1831_24"
          x1="6.78622"
          y1="-1.80521"
          x2="-9.77099"
          y2="14.7897"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD800" />
          <stop offset="1" stopColor="#00FF80" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1831_24"
          x1="16.7033"
          y1="14.5093"
          x2="6.99796"
          y2="25.1071"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FD00C3" />
          <stop offset="1" stopColor="#A101FF" />
        </linearGradient>
      </defs>
    </BaseIcon>
  );
}
