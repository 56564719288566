import { defActionTypes } from '../../utils/defActionTypes';

export const SET_APPLICATION_DATA = 'SET_APPLICATION_DATA';
export const RESET_APP = 'RESET_APP';
export const SIGNED_AT = 'SIGNED_AT';
export const SELECT_OFFER = 'SELECT_OFFER';

export const SET_REQUESTED_AMOUNT = 'SET_REQUESTED_AMOUNT';

export const SET_ALPHAEON_EXTRA = 'SET_ALPHAEON_EXTRA';

export const SET_PAYMENT_DETAILS = 'SET_PAYMENT_DETAILS';
export const SET_HFD_PAYMENT_DETAILS = 'SET_HFD_PAYMENT_DETAILS';
export const SET_EMPLOYMENT_DETAILS = 'SET_EMPLOYMENT_DETAILS';
export const SET_GREAT_AMERICA_DETAILS = 'SET_GREAT_AMERICA_DETAILS';

export const SET_ANIMATION_FOR_LENDER = 'SET_ANIMATION_FOR_LENDER';
export const LENDER_APPLICATION_UPDATE = 'LENDER_APPLICATION_UPDATE';

export const ENABLE_HARDPULL_DECLINE_NOTIFICATION = 'ENABLE_HARDPULL_DECLINE_NOTIFICATION';
export const DISABLE_HARDPULL_DECLINE_NOTIFICATION = 'DISABLE_HARDPULL_DECLINE_NOTIFICATION';

export const approveLenderApplicationAction = defActionTypes('approve_lender_Application');
export const getLenderApplicationAction = defActionTypes('get_lender_Application');
export const reApplyLenderApplicationAction = defActionTypes('reapply_lender_Application');
export const setPaymentDateForHfdAction = defActionTypes('set_Payment_Date_For_Hfd');
export const setAdminPaymentDateForHfdAction = defActionTypes('set_Admin_Payment_Date_For_Hfd');
export const getLenderRedirectAction = defActionTypes('get_lender_redirect');
export const resetLenderApplicationAction = defActionTypes('reset_lender_application');
export const requestIncreasedAmountAction = defActionTypes('request_increased_amount');
export const getCanChargeAction = defActionTypes('get_can_charge');
export const extendCareCreditSessionAction = defActionTypes('extend_care_credit_session');
export const moveApplicationToAnotherLocationAction = defActionTypes('move_application_to_another_location');
