import { AnyAction } from 'redux';
import { getCustomerActions } from './actionTypes';
import { CustomerReducer } from './types';
import { RESET_APP } from '../app';

const initialState: CustomerReducer = {
  data: undefined,
  status: 'initial',
};

export function customerReducer(state = initialState, action: AnyAction): CustomerReducer {
  const { payload, type } = action;
  switch (type) {
    case getCustomerActions.REQUEST:
      return {
        ...state,
        status: 'loading',
        error: null,
      };
    case getCustomerActions.SUCCESS:
      return {
        ...state,
        data: payload.data,
        status: 'loaded',
      };
    case getCustomerActions.FAILURE:
      return {
        ...state,
        status: 'error',
        error: payload,
      };
    case RESET_APP: {
      return {
        ...state,
        ...initialState,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
