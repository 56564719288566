import { defThunkRequest } from '../../utils/defAsyncAction';
import { IInvite, IInviteBody, IRetryInviteBody } from '../../types/invite';
import { getInviteDetailsRequest, inviteSmsRequest, retryInviteSmsRequest } from '../apiRequests/sendSms';
import { getInviteDetailsAction, inviteSmsAction, retryInviteSmsAction } from './actionTypes';

export const inviteSmsThunk = defThunkRequest<{
  body: IInviteBody;
  location?: string;
}, IInvite>({
  actionTypes: inviteSmsAction,
  thunkSteps: [
    ({
      data: {
        body,
        location,
      },
    }: any) => {
      return inviteSmsRequest(body, location);
    },
  ],
});

export const retryInviteSmsThunk = defThunkRequest<IRetryInviteBody, IInvite>({
  actionTypes: retryInviteSmsAction,
  thunkSteps: [
    ({
      data,
    }) => {
      return retryInviteSmsRequest(data);
    },
  ],
});

export const getInviteDetailsThunk = defThunkRequest<{
  inviteKey: string;
}, IInvite>({
  actionTypes: getInviteDetailsAction,
  thunkSteps: [
    ({
      data: {
        inviteKey,
      },
    }: any) => {
      return getInviteDetailsRequest(inviteKey);
    },
  ],
});
