import React from 'react';
import {
  createStyles, Link, LinkProps, makeStyles,
} from '@material-ui/core';
import cx from 'classnames';
import { COLORS } from 'applicationConstants';
import ArrowRight24 from '../Icons/24/ArrowRight24';

type Props = {
  color?: string;
  useTextDecoration?: boolean;
  text: string;
  hideIcon?: boolean;
  component?: any;
} & Omit<LinkProps, 'color'>;

type StyleProps = Pick<Props, 'color' | 'useTextDecoration' >;

const useStyles = makeStyles(() => createStyles({
  link: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: ({ useTextDecoration }: StyleProps) => (useTextDecoration ? 'underline' : 'none'),

    color: ({ color }: StyleProps) => color || COLORS.mainBlue,
  },
}));

export const CcLink = ({
  color,
  useTextDecoration,
  text,
  hideIcon,
  variant = 'subtitle2',
  ...props
}: Props) => {
  const styles = useStyles({ useTextDecoration, color });

  return (
    <Link
      {...props}
      variant={variant}
      className={cx(styles.link, props.className)}
    >
      {text}
      {!hideIcon && <ArrowRight24 />}
    </Link>
  );
};
