/* eslint-disable max-len */
import React from 'react';
import { IconProps } from 'components/Icons/types';
import { BaseIcon } from 'components/Icons/BaseIcon';

export default function Check24({
  color = '#090224',
  width,
  height,
  position,
}: IconProps) {
  return (
    <BaseIcon
      sizeX={24}
      sizeY={24}
      width={width}
      height={height}
      color={color}
      position={position}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0657 5.93433C20.3781 6.24675 20.3781 6.75328 20.0657 7.0657L10 17.1314L4.43431 11.5657C4.12189 11.2533 4.12189 10.7467 4.43431 10.4343C4.74673 10.1219 5.25326 10.1219 5.56568 10.4343L10 14.8686L18.9343 5.93433C19.2467 5.62191 19.7533 5.62191 20.0657 5.93433Z"
      />
    </BaseIcon>
  );
}
