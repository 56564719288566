import { apiRequest } from '../../utils/apiRequest';
import { toParams } from '../../utils/params';
import { IInviteBody, IRetryInviteBody } from '../../types/invite';

export const inviteSmsRequest = (
  body: IInviteBody,
  location?: string,
) => apiRequest(
  `invite${toParams({ location })}`,
  {
    method: 'POST',
    body,
  },
);

export const retryInviteSmsRequest = ({
  recipientPhone,
  recipientEmail,
  inviteKey,
}:IRetryInviteBody) => apiRequest(
  `invite/retry${toParams({ invite: inviteKey })}`,
  {
    method: 'PATCH',
    body: {
      recipientPhone,
      recipientEmail,
    },
  },
);

export const getInviteDetailsRequest = (
  inviteKey: string,
) => apiRequest(
  `invite/${inviteKey}`,
  {
    method: 'GET',
  },
);
