import { createStore, compose, applyMiddleware } from 'redux';

import thunk from 'redux-thunk';

import rootReducer from './rootReducer';
import { NavigationMiddleware } from './navigationMiddleware';
import {
  IS_REDUX_INSPECTOR_ENABLED,
} from '../applicationConstants/envVariables';
import { sentryReduxEnhancer } from './sentryReduxEnhancer';

const getComposeEnhancers = () => {
  if (!IS_REDUX_INSPECTOR_ENABLED) {
    return compose;
  }

  return (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
};

const createEnhancer = () => {
  const composeEnhancers = getComposeEnhancers();

  const enhancers = [];
  enhancers.push(
    applyMiddleware(NavigationMiddleware, thunk),
    sentryReduxEnhancer,
  );
  return composeEnhancers(...enhancers);
};

export const createReduxStore = (initialState: any = {}) => {
  return createStore(rootReducer, initialState, createEnhancer());
};
