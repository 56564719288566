/* eslint-disable max-len */
import React from 'react';
import { IconProps } from 'components/Icons/types';
import { BaseIcon } from 'components/Icons/BaseIcon';

export default function Close24({
  color = '#090224',
  position,
}: IconProps) {
  return (
    <BaseIcon
      sizeX={24}
      sizeY={24}
      color={color}
      position={position}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.36465 6.23325C7.05223 5.92083 6.5457 5.92083 6.23328 6.23325C5.92086 6.54567 5.92086 7.0522 6.23328 7.36462L10.8687 12L6.23328 16.6353C5.92086 16.9478 5.92086 17.4543 6.23328 17.7667C6.5457 18.0791 7.05223 18.0791 7.36465 17.7667L12 13.1314L16.6354 17.7667C16.9478 18.0791 17.4543 18.0791 17.7668 17.7667C18.0792 17.4543 18.0792 16.9477 17.7668 16.6353L13.1314 12L17.7668 7.36464C18.0792 7.05222 18.0792 6.54569 17.7668 6.23327C17.4543 5.92085 16.9478 5.92085 16.6354 6.23327L12 10.8686L7.36465 6.23325Z"
      />
    </BaseIcon>
  );
}
