import React from 'react';
import { createStyles, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import ladiesStyles from 'assets/scss/ladiesStyles.module.scss';
import AppHeader from '../../AppHeader';
import { MobileGoBack } from '../MobileGoBack';
import { useLadyAnimation } from './useLadyAnimation';
import { ApplicationNavigationContext, useApplicationNavigationContextData } from '../ApplicationNavigationContext';
import { ApplicationProgress } from '../ApplicationProgress';
import { useCustomHistory } from '../../../hooks/useCustomHistory';

type Props = {
  children: React.ReactNode;
  ladyStyle?:
    'lady1BlueCircleWithOuterCircle'
    | 'lady1RedCircleWithOuterCircle'
    | 'lady7BlueCircleWithOuterCircle'
    | 'lady7RedCircleWithOuterCircle'
    | 'lady4RedCircleWithOuterCircle'
    | 'lady4GreenCircleWithOuterCircle'
    | 'lady6GreenCircleWithOuterCircle'
    | 'lady6RedCircleWithOuterCircle'
    | 'lady2RedCircleWithOuterCircle'
    | 'lady4GreenCircle';
  step?: number;
  steps?: number;
  goBack?: string | boolean
  useMobileGoBack?: boolean;
};

const useStyles = makeStyles((theme) => createStyles({
  appContent: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    height: '100%',
  },
  appContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    overflow: 'auto',
    backgroundColor: '#fcfdfd',
    paddingTop: '112px',
    paddingBottom: 30,
    [theme.breakpoints.up('desktop')]: {
      paddingBottom: 0,
      minHeight: '100%',
    },
  },
  mobileContent: {
    overflow: 'visible',
    paddingTop: 0,
  },
}));

export const ApplicationContainerInner = ({
  children,
  ladyStyle,
  useMobileGoBack,
  step,
  steps,
  goBack,
}: Props) => {
  const classes = useStyles();
  const history = useCustomHistory();
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('desktop'));
  const containerRef = useLadyAnimation(ladyStyle);

  const onGoBack = () => {
    if (typeof goBack === 'boolean') {
      history.goBack();
    }
    if (typeof goBack === 'string') {
      history.push(goBack);
    }
  };

  return (
    <div className={classes.appContent}>
      {typeof steps === 'number' && steps > 0 && step && isDesktop && (
        <ApplicationProgress
          step={step}
          steps={steps}
          onGoBack={goBack ? onGoBack : undefined}
        />
      )}
      <AppHeader />
      <div
        id="application-page"
        className={cx(classes.appContentWrapper,
          !isDesktop && classes.mobileContent,
          isDesktop && ladyStyle && ladiesStyles[ladyStyle])}
        ref={containerRef}
      >
        {children}
        {
            useMobileGoBack && !isDesktop && (
            <MobileGoBack onGoBack={onGoBack} />
            )
        }
      </div>
    </div>
  );
};

export const ApplicationFlowTemplate = ({
  children,
  steps,
}: Omit<Props, 'ladyStyle' | 'step' | 'goBack'>) => {
  const contextData = useApplicationNavigationContextData();

  return (
    <ApplicationNavigationContext.Provider value={contextData}>
      <ApplicationContainerInner
        ladyStyle={contextData.ladyStyle}
        step={contextData.currentStep}
        steps={steps}
        goBack={contextData.goBack}
        useMobileGoBack={contextData.useMobileGoBack}
      >
        {children}
      </ApplicationContainerInner>
    </ApplicationNavigationContext.Provider>
  );
};
