/* eslint-disable max-len */
import React from 'react';
import { IconProps } from 'components/Icons/types';
import { BaseIcon } from 'components/Icons/BaseIcon';

export default function ArrowRight24({
  color = '#090224',
  position,
}: IconProps) {
  return (
    <BaseIcon
      sizeX={24}
      sizeY={24}
      color={color}
      position={position}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.68431 19.3157C9.37189 19.0033 9.37189 18.4967 9.68431 18.1843L15.6186 12.25L9.68431 6.31567C9.37189 6.00325 9.37189 5.49672 9.68431 5.1843C9.99673 4.87188 10.5033 4.87188 10.8157 5.1843L17.3157 11.6843C17.6281 11.9967 17.6281 12.5033 17.3157 12.8157L10.8157 19.3157C10.5033 19.6281 9.99673 19.6281 9.68431 19.3157Z"
      />
    </BaseIcon>
  );
}
