import {
  createNetworkActions, updateNetworkActions,
} from './actionTypes';
import { defThunkRequest } from '../../utils/defAsyncAction';
import {
  createNetworkApiRequest,
  CreateNetworkBody, updateNetworkApiRequest,
} from '../apiRequests/networks';

export const createNetworkThunk = defThunkRequest<CreateNetworkBody>({
  actionTypes: createNetworkActions,
  thunkSteps: [
    ({ data }) => createNetworkApiRequest(data),
  ],
});

export const updateNetworkThunk = defThunkRequest<{id: string; body: CreateNetworkBody}>({
  actionTypes: updateNetworkActions,
  thunkSteps: [
    ({ data }) => updateNetworkApiRequest(data.id, data.body),
  ],
});
