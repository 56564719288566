import { defActionTypes } from '../../utils/defActionTypes';
import { defAction } from '../../utils/defAction';
import { ISubmission } from '../../types/submissions';

export const getApplicationsAction = defActionTypes('get_applications_list');

export const newTransactionAction = defActionTypes('new_Transaction_Action');
export const clearApplicationsList = defAction('clear_applications_list');
export const updateSubmissionListItem = defAction<ISubmission>('update_submission_list_item');
export const updateSubmissionCurrentLender = defAction<ISubmission>('update_submission_current_lender');
