import {
  createPreSaleApiRequest,
  CreatePreSaleBody,
  disablePreSaleApiRequest,
  getPreSalesApiRequest,
} from '../apiRequests';
import { defThunkRequest } from '../../utils/defAsyncAction';
import { IPaginationResponse } from '../../types/otherData';
import {
  getPreSaleListActions,
  disablePreSaleActions,
  createPreSaleActions,
} from './actionTypes';
import { PreSale } from '../../types/preSale';

export const getPreSaleListThunk = defThunkRequest<{
  getOnlyCurrent?: boolean,
  count?: number
}, IPaginationResponse<PreSale>>({
  actionTypes: getPreSaleListActions,
  thunkSteps: [
    ({ data }) => getPreSalesApiRequest(data.getOnlyCurrent, data.count),
  ],
});

export const createPreSaleThunk = defThunkRequest<CreatePreSaleBody, IPaginationResponse<PreSale>>({
  actionTypes: createPreSaleActions,
  thunkSteps: [
    ({ data }) => createPreSaleApiRequest(data),
  ],
});
export const disablePreSaleThunk = defThunkRequest<{ id: string }, IPaginationResponse<PreSale>>({
  actionTypes: disablePreSaleActions,
  thunkSteps: [
    ({ data: { id } }) => disablePreSaleApiRequest(id),
  ],
});
