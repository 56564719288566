import React, { useContext, useMemo } from 'react';
import memoize from 'lodash/memoize';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import log from 'loglevel';

import allStrings from 'assets/strings';
import { IS_DEV, IS_LOCAL } from '../applicationConstants/envVariables';
import { networkStrings } from '../assets/networkStrings';
import { useCcConfigurationContext } from '../contexts/ApplicationConfiguration';
import { LENDERS_TYPE } from '../applicationConstants';

export type GetText = (value: string) => string;

const getGeneralString = (networkBasedStrings: any) => {
  return merge(
    cloneDeep(allStrings.general),
    get(networkBasedStrings, 'general', {}),
  );
};

const getStrings = (lenders?: string | string[], networkType?: string) => {
  let networkBasedStrings = {};
  const networkValues = networkType && get(networkStrings, networkType);
  if (networkValues) {
    networkBasedStrings = networkValues;
  }
  const defaultStrings = getGeneralString(networkBasedStrings);
  if (!lenders) {
    return defaultStrings;
  }
  if (!Array.isArray(lenders)) {
    if (!get(allStrings, lenders)) {
      log.error('Passed lender does not exists', lenders);
      return defaultStrings;
    }
    return merge(
      defaultStrings,
      get(allStrings, lenders),
      get(networkBasedStrings, lenders, {}),
    );
  }

  const strings = lenders.map((x) => get(allStrings, x, {}));
  const networkLenderStrings = lenders.map((x) => get(networkBasedStrings, x, {}));
  return merge(defaultStrings, ...strings, ...networkLenderStrings);
};

const createGetText = memoize((lenders?: string | string[], networkType?: string) => {
  const strings = getStrings(lenders, networkType);
  return (textPath: string) => {
    const text = get(strings, textPath);

    if (!text) {
      return IS_DEV || IS_LOCAL ? textPath : '';
    }

    return text;
  };
});

type ContextProps = {
  lender?: LENDERS_TYPE;
  lenders?: Array<LENDERS_TYPE>;
};

export const TextMapperContext = React.createContext<ContextProps>({
});

export const TextMapperProvider = ({ lender, lenders, children }: {
  children: React.ReactNode
} & ContextProps) => {
  const data = useMemo(() => {
    return { lender, lenders };
  }, [lender, lenders]);
  return (
    <TextMapperContext.Provider value={data}>
      {children}
    </TextMapperContext.Provider>
  );
};

export const useTextMapper = (localLenders?: string | string[]) => {
  const { networkType } = useCcConfigurationContext();
  const { lender: contextLender, lenders } = useContext(TextMapperContext);

  return useMemo(() => {
    if (Array.isArray(localLenders)) {
      const textMappers = localLenders.map((x) => createGetText(x, networkType));

      return (key: string) => {
        const uniqText = uniq(textMappers.map((func) => func(key)));
        return uniqText.length === 1 ? uniqText[0] : uniqText;
      };
    }
    if (localLenders) {
      return createGetText(localLenders, networkType);
    }

    if (contextLender) {
      return createGetText([contextLender], networkType);
    }

    return createGetText(lenders, networkType);
  }, [contextLender, lenders, localLenders, networkType]);
};
