import React from 'react';
import { Box, TypographyProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CcTypography } from '../CcTypography';

type Props = {
  color?: string;
  textColor?: string;
  text?: string;
  lineHeight?: number;
  useText?: boolean;
  textVariant?: TypographyProps['variant'];
};

const useStyles = makeStyles({
  line: {
    width: '100%',
    height: 0,
    borderBottom: ({ color, lineHeight }: Partial<Props>) => `${lineHeight}px solid ${color}`,
  },
  lineBlock: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  orElement: {
    marginLeft: 'var(--spacing-s)',
    marginRight: 'var(--spacing-s)',
  },
});

export const HorizontalSeparator = ({
  color = '#E5EAF9',
  lineHeight = 1,
  useText = true,
  text = 'or',
  textColor,
  textVariant = 'caption',
}: Props) => {
  const styles = useStyles({ color, lineHeight });

  return (
    <Box className={styles.lineBlock}>
      <div className={styles.line} />
      {
            useText && (
            <CcTypography
              variant={textVariant}
              className={styles.orElement}
              customColor={textColor}
            >
              {text}
            </CcTypography>
            )
        }
      <div className={styles.line} />
    </Box>
  );
};
