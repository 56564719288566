/* eslint-disable max-len */
import { LENDERS_TYPE } from './enums';
import { usdToCents } from '../utils/moneyFormatting';

export const IS_DEV = process.env.REACT_APP_MODE === 'development';
export const IS_PROD = process.env.REACT_APP_MODE === 'production';
export const IS_LOCAL = process.env.REACT_APP_MODE === 'local_development';
export const IS_LOGGING_ENABLED = process.env.REACT_APP_ENABLE_LOGGING === 'true';
export const SHOW_COPY_LINK_TEXT_TO_PAY = process.env.REACT_APP_SHOW_COPY_LINK_TEXT_TO_PAY === 'true';
export const IS_REDUX_INSPECTOR_ENABLED = process.env.REACT_APP_ENABLE_REDUX_INSPECTOR === 'true';
export const DISABLE_CELEBRATION = process.env.REACT_APP_DISABLE_CELEBRATION === 'true';
export const RELEASE_NOTES_URL = process.env.REACT_APP_RELEASE_NOTES_URL;
export const FAQ_URL = process.env.REACT_APP_FAQ_URL;
export const ADDRESS_API_KEY = process.env.REACT_APP_ADDRESS_API_KEY;
export const SUPPORT_WIDGET_PROVIDER = process.env.REACT_APP_SUPPORT_PROVIDER;
export const SUPPORT_CLARITY_KEY = process.env.REACT_APP_CLARITY_KEY;
export const SUPPORT_LOGROCKET_KEY = process.env.REACT_APP_LOGROCKET_KEY;
export const ALLOW_IDEAL_STACKING_CHARGES = process.env.REACT_APP_ALLOW_IDEAL_STACKING_CHARGES === 'true';
export const LENDERS_SUPPORT_ITIN = (process.env.REACT_APP_LENDERS_SUPPORT_ITIN || '')
  .split(',').map((x) => x.trim()) as LENDERS_TYPE[];
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || '';
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE || '';
export const IS_WS_APPLICATION_FLOW_TYPE = process.env.REACT_APP_APPLICATION_FLOW === 'ws';
export const MILAN_DEFAULT_REQUESTED_AMOUNT = usdToCents(process.env.REACT_APP_MILAN_DEFAULT_REQUESTED_AMOUNT || '');
export const MILAN_DEFAULT_OFFERS_AMOUNT = usdToCents(process.env.REACT_APP_MILAN_DEFAULT_OFFERS_AMOUNT || '');
export const SHOW_LENDER_CONFIGURATION_SCREENS = IS_LOCAL
    && process.env.REACT_APP_SHOW_LENDER_CONFIGURATIONS_IN_LOCAL_DEV === 'true';
export const SUPPORTED_LENDERS_IN_DEPLOYMENT = (process.env.REACT_APP_SUPPORTED_LENDERS || '')
  .split(',')
  .map((x: string) => x.trim())
  .filter((x) => x !== '');
export const SUPPORTED_SERVICE_LENDERS_IN_DEPLOYMENT = (process.env.REACT_APP_SERVICE_LENDERS || '')
  .split(',')
  .map((x: string) => x.trim())
  .filter((x) => x !== '');
