import React from 'react';
import cx from 'classnames';
import { IconProps } from './types';

type Props = {
    children?: React.ReactNode;
    customViewBox?: string;
    sizeX: number | string;
    sizeY: number | string;
    width?: number;
    height?: number;
} & IconProps;

export const BaseIcon = ({
  children,
  position,
  sizeX,
  sizeY,
  color = 'none',
  className,
  customViewBox,
  width,
  height,
}: Props) => {
  return (
    <svg
      width={width || sizeX}
      height={height || sizeY}
      viewBox={customViewBox || `0 0 ${sizeX} ${sizeY}`}
      fill={color}
      style={{ flexShrink: 0 }}
      xmlns="http://www.w3.org/2000/svg"
      className={cx(position, className)}
    >
      {children}
    </svg>
  );
};
