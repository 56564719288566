import get from 'lodash/get';
import log from 'loglevel';
import {
  SET_LOADER,
  RESET_APP,
  UNEXPECTED_ERROR,
  RESET_LOADING_COUNTER,
} from './actionTypes';
import { defAction } from '../../utils/defAction';

export function setLoader(loader: boolean) {
  return {
    type: SET_LOADER,
    payload: { loader },
  };
}

export function resetLoader() {
  return {
    type: RESET_LOADING_COUNTER,
  };
}

export function resetApp() {
  return {
    type: RESET_APP,
  };
}

export function handleUnexpectedError(errorMessage: any, history: any, raw: any) {
  return {
    type: UNEXPECTED_ERROR,
    payload: errorMessage,
    history,
    raw,
  };
}

export const handleApiError = ({
  dispatch, error, requestName, history, additionalAction,
}: any) => {
  let errorMessage = error ? error.toString() : error;
  if (get(error, 'data')) {
    errorMessage = get(error, 'data');
  }
  log.error(requestName, error);
  if (additionalAction) {
    dispatch(additionalAction(errorMessage, history));
  }
  dispatch(handleUnexpectedError(errorMessage, history, error));
};

export const toggleFiltrationOfWsUpdates = defAction('toggle_Filtration_Of_Ws_Updates');
