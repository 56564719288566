/* eslint-disable max-len */
import React from 'react';
import { IconProps } from 'components/Icons/types';
import { BaseIcon } from 'components/Icons/BaseIcon';

export default function ColoredInfo32({
  position,
}: IconProps) {
  return (
    <BaseIcon
      sizeX={32}
      sizeY={32}
      color="none"
      position={position}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        fill="#EB7A28"
      />
      <path
        d="M15.9987 8.33398C14.4824 8.33398 13.0001 8.78363 11.7393 9.62605C10.4786 10.4685 9.4959 11.6658 8.91562 13.0667C8.33535 14.4676 8.18353 16.0092 8.47935 17.4963C8.77517 18.9835 9.50535 20.3496 10.5775 21.4218C11.6498 22.494 13.0158 23.2242 14.503 23.52C15.9902 23.8158 17.5317 23.664 18.9326 23.0837C20.3335 22.5035 21.5309 21.5208 22.3733 20.26C23.2157 18.9992 23.6654 17.517 23.6654 16.0007C23.6631 13.968 22.8546 12.0193 21.4173 10.582C19.98 9.14475 18.0313 8.33628 15.9987 8.33398ZM15.6654 20.6673C15.5335 20.6673 15.4046 20.6282 15.295 20.555C15.1854 20.4817 15.0999 20.3776 15.0494 20.2558C14.999 20.134 14.9858 19.9999 15.0115 19.8706C15.0372 19.7413 15.1007 19.6225 15.194 19.5292C15.2872 19.436 15.406 19.3725 15.5353 19.3468C15.6646 19.3211 15.7987 19.3343 15.9205 19.3847C16.0423 19.4352 16.1464 19.5206 16.2197 19.6303C16.2929 19.7399 16.332 19.8688 16.332 20.0007C16.332 20.1775 16.2618 20.347 16.1368 20.4721C16.0117 20.5971 15.8422 20.6673 15.6654 20.6673ZM18.6084 14.2007C18.5206 14.4468 18.3943 14.6775 18.234 14.884C17.9077 15.259 17.551 15.6064 17.1674 15.9227C16.8301 16.1846 16.5368 16.4988 16.2987 16.8533C16.1442 17.1594 16.0713 17.5002 16.087 17.8427V18.0577H15.2457V17.706C15.2301 17.2761 15.3098 16.8481 15.479 16.4527C15.6962 16.0508 15.993 15.6975 16.3514 15.4143C16.7726 15.0909 17.1483 14.712 17.468 14.288C17.6285 14.0117 17.7085 13.696 17.699 13.3767C17.7102 13.1846 17.6777 12.9926 17.6038 12.8149C17.53 12.6373 17.4168 12.4788 17.2727 12.3513C16.9197 12.0886 16.4845 11.9605 16.0454 11.99C15.7075 11.9893 15.3708 12.0297 15.0427 12.1103C14.6544 12.2151 14.2789 12.3624 13.923 12.5497L13.539 11.671C14.3304 11.2485 15.2136 11.0271 16.1107 11.0263C16.8088 10.9831 17.498 11.2013 18.044 11.6383C18.2788 11.8575 18.4624 12.1257 18.5817 12.424C18.701 12.7222 18.7532 13.043 18.7344 13.3637C18.7398 13.6478 18.6972 13.9307 18.6084 14.2007Z"
        fill="white"
      />
    </BaseIcon>
  );
}
