import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as newRoutes from 'pages/wsApplication/applicationRoutes';
import * as originalRouts from '../../routes';

const loaderContainer = document.getElementById('loader-root');

const Loader = () => {
  const { pathname } = useLocation();
  const loaderStatus = useSelector((state) => state.app.loader);

  useEffect(() => {
    const hideForSpecialLoadingPage = originalRouts.SUBMISSION_LOADING_PAGE.isMatch(pathname)
        || originalRouts.APPROVING_LOADING_PAGE.isMatch(pathname)
        || newRoutes.SUBMISSION_LOADING_PAGE.isMatch(pathname)
        || newRoutes.APPROVING_LOADING_PAGE.isMatch(pathname);

    if (!loaderContainer) return () => {};
    if (!loaderStatus || hideForSpecialLoadingPage) {
      loaderContainer.style.display = 'none';
      return () => {};
    }
    const inputElement = loaderContainer.getElementsByTagName('input').item(0);
    if (inputElement) {
      inputElement.focus();
    }

    loaderContainer.style.display = 'flex';

    return () => {
      if (!loaderContainer) return;
      loaderContainer.style.display = 'none';
    };
  }, [loaderStatus, pathname]);

  return null;
};

export default Loader;
