/* eslint-disable max-len */
import React from 'react';
import { IconProps } from 'components/Icons/types';
import { BaseIcon } from 'components/Icons/BaseIcon';

export default function Tablet({
  position,
}: IconProps) {
  return (
    <BaseIcon
      sizeX={32}
      sizeY={32}
      color="none"
      position={position}
    >
      <rect y="10.9758" width="32" height="10.0483" fill="#4803FF" />
      <path
        d="M0.000482559 21.0241V10.9113C6.00052 10.9113 10.8645 6.02612 10.8645 -3.8147e-06H20.9336C20.9336 11.6113 11.5615 21.0241 0.000482559 21.0241Z"
        fill="url(#paint0_linear_1986_1312)"
      />
      <path
        d="M32.0003 10.9758V21.0888C26.0003 21.0888 21.1363 25.9739 21.1363 32.0001H11.0671C11.0671 20.3887 20.4393 10.9758 32.0003 10.9758Z"
        fill="url(#paint1_linear_1986_1312)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1986_1312"
          x1="7.65784"
          y1="-18.8885"
          x2="-18.8339"
          y2="7.66359"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD800" />
          <stop offset="1" stopColor="#00FF80" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1986_1312"
          x1="23.5253"
          y1="7.2149"
          x2="7.99661"
          y2="24.1717"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FD00C3" />
          <stop offset="1" stopColor="#A101FF" />
        </linearGradient>
      </defs>
    </BaseIcon>
  );
}
