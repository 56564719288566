import { Customer } from 'types/customer';
import {
  assignCustomerApiRequest, AssignCustomerPayload,
  getCustomerApiRequest,
} from '../apiRequests';
import { defThunkRequest } from '../../utils/defAsyncAction';
import {
  assignCustomerActions,
  getCustomerActions,
} from './actionTypes';

export const getCustomerThunk = defThunkRequest<{id: string}, Customer>({
  actionTypes: getCustomerActions,
  thunkSteps: [
    ({ data }) => getCustomerApiRequest(data.id),
  ],
});

export const assignCustomerThunk = defThunkRequest<AssignCustomerPayload, Customer>({
  actionTypes: assignCustomerActions,
  thunkSteps: [
    ({ data }) => assignCustomerApiRequest(data),
  ],
});
