import { AnyAction } from 'redux';
import { RESET_APP } from 'store/app';
import { defaultApplicationFormValues, LENDER_APPLICATION_STATUSES } from 'applicationConstants';

import { v4 as uuid } from 'uuid';
import {
  SET_APPLICATION_DATA,
  SIGNED_AT,
  SELECT_OFFER,
  approveLenderApplicationAction,
  getLenderApplicationAction,
  SET_REQUESTED_AMOUNT,
  SET_EMPLOYMENT_DETAILS,
  SET_PAYMENT_DETAILS,
  SET_HFD_PAYMENT_DETAILS,
  SET_ALPHAEON_EXTRA,
  SET_ANIMATION_FOR_LENDER,
  SET_GREAT_AMERICA_DETAILS,
  ENABLE_HARDPULL_DECLINE_NOTIFICATION,
  DISABLE_HARDPULL_DECLINE_NOTIFICATION,
  setAdminPaymentDateForHfdAction, LENDER_APPLICATION_UPDATE, getCanChargeAction,
} from './actionTypes';
import { IApplicationReducer } from './types';
import { IApplication, ILenderApplication } from '../../types/application';
import { includesInUnion } from '../../utils/includesInUnion';
import { submitKbaChallengeAction } from '../kbaChallenge';

type UpdateType = {
  wsUpdateType?: 'applicationChanged';
  updateUUIID?: string;
};

const initialState: IApplicationReducer = {
  formData: { ...defaultApplicationFormValues },
  canCharge: false,
  canChargeStatus: 'initial',
  signedAt: undefined,
  applicationError: undefined,
  data: {} as ILenderApplication,
  status: 'initial',
  lenderAmounts: {
    loanAmount: 0,
    creditLimit: 0,
  },
  alphaeonExtra: undefined,
  selectedOffer: undefined,
  employmentDetails: {},
  paymentDetails: {},
  hfdPaymentDetails: {},
  lendersAnimationPlayed: false,
  enableHardpullDeclineNotification: false,
  greatAmericaDetails: {
    documentType: '',
    documentState: '',
    documentNumber: '',
    employerName: '',
    employerPhone: '',
  },
};

const getWSUpdateType = (app1: IApplication, app2: IApplication): UpdateType => {
  if (
    (app1.stage === app2.stage
    && (app1.status !== app2.status || app1.inRequest !== app2.inRequest)
    && (includesInUnion([
      LENDER_APPLICATION_STATUSES.callback,
      LENDER_APPLICATION_STATUSES.pending,
      LENDER_APPLICATION_STATUSES.timeout,
    ], app2.status) || app2.inRequest))
    || (app1.stage === app2.stage
    && app1.status === app2.status)
  ) {
    return {};
  }

  return {
    wsUpdateType: 'applicationChanged',
    updateUUIID: uuid(),
  };
};

export function applicationReducer(
  state = initialState,
  action: AnyAction,
): IApplicationReducer & UpdateType {
  const { payload, type } = action;
  switch (type) {
    case SET_ANIMATION_FOR_LENDER:
      return {
        ...state,
        lendersAnimationPlayed: true,
      };
    case ENABLE_HARDPULL_DECLINE_NOTIFICATION:
      return {
        ...state,
        enableHardpullDeclineNotification: true,
      };
    case SET_ALPHAEON_EXTRA:
      return {
        ...state,
        alphaeonExtra: payload,
      };
    case DISABLE_HARDPULL_DECLINE_NOTIFICATION:
      return {
        ...state,
        enableHardpullDeclineNotification: false,
      };
    case getCanChargeAction.SUCCESS:
      return {
        ...state,
        canChargeStatus: 'loaded',
        canCharge: !!payload.data,
      };
    case getCanChargeAction.FAILURE:
      return {
        ...state,
        canCharge: false,
      };
    case SET_PAYMENT_DETAILS:
      return {
        ...state,
        paymentDetails: payload,
      };
    case SET_EMPLOYMENT_DETAILS:
      return {
        ...state,
        employmentDetails: payload,
      };
    case SET_HFD_PAYMENT_DETAILS:
      return {
        ...state,
        hfdPaymentDetails: payload,
      };
    case SET_APPLICATION_DATA:
      return {
        ...state,
        formData: { ...defaultApplicationFormValues, ...payload.data },
      };
    case SET_GREAT_AMERICA_DETAILS:
      return {
        ...state,
        greatAmericaDetails: payload,
      };
    case SIGNED_AT:
      return {
        ...state,
        signedAt: action.payload.signedAt,
      };
    case SET_REQUESTED_AMOUNT:
      return {
        ...state,
        lenderAmounts: {
          ...state.lenderAmounts,
          ...payload,
        },
      };
    case setAdminPaymentDateForHfdAction.SUCCESS:
    case approveLenderApplicationAction.SUCCESS:
    case getLenderApplicationAction.SUCCESS: {
      return {
        ...state,
        status: 'loaded',
        data: action.payload.data,
      };
    }
    case LENDER_APPLICATION_UPDATE: {
      if (
        action.payload.id !== state.data.application?.id
        || action.payload.status === LENDER_APPLICATION_STATUSES.locked
        || action.payload.inRequest
      ) {
        return state;
      }
      return {
        ...state,
        status: 'loaded',
        data: {
          ...state.data,
          application: action.payload,
        },
        ...getWSUpdateType(action.payload, state.data.application),
      };
    }
    case submitKbaChallengeAction.SUCCESS: {
      return {
        ...state,
        status: 'loaded',
        data: {
          ...state.data,
          application: action.payload.data,
        },
      };
    }
    case approveLenderApplicationAction.FAILURE:
    case getLenderApplicationAction.FAILURE:
      return {
        ...state,
        status: 'error',
        applicationError: action.payload,
      };
    case SELECT_OFFER:
      return {
        ...state,
        selectedOffer: action.payload,
      };
    case RESET_APP:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
}
