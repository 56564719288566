let careCreditTools: string | undefined;
export const setCareCreditToken = (token?: string) => {
  careCreditTools = token;
};

export const getCareCreditToken = () => careCreditTools;

let last4Ssn = '';
export const setLast4Ssn = (value: string) => {
  last4Ssn = value;
};

export const getLast4Ssn = () => {
  return last4Ssn;
};
