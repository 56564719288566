import { AnyAction } from 'redux';
import { getCustomerInviteLocationActions } from './actionTypes';
import { CustomerInviteLocationReducer } from './types';

const initialLocationsState: CustomerInviteLocationReducer = {
  data: undefined,
  status: 'initial',
};

export function customerInviteLocationReducer(
  state = initialLocationsState,
  action: AnyAction,
): CustomerInviteLocationReducer {
  const { payload, type } = action;
  switch (type) {
    case getCustomerInviteLocationActions.REQUEST:
      return {
        ...state,
        status: 'loading',
        error: null,
      };
    case getCustomerInviteLocationActions.SUCCESS:
      return {
        ...state,
        data: payload.data,
        status: 'loaded',
      };
    case getCustomerInviteLocationActions.FAILURE:
      return {
        ...state,
        status: 'error',
        error: payload,
      };
    default:
      return {
        ...state,
      };
  }
}
