import { useCallback, useEffect, useRef } from 'react';
import { useMediaQuery } from '@material-ui/core';
import {
  cirsleAnimation, clearCircleAnimation,
} from 'utils/animation';

export const useLadyAnimation = (ladyStyle?: string) => {
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('desktop'));

  const containerRef = useRef(null);
  useEffect(() => {
    clearCircleAnimation(containerRef.current);
  }, [ladyStyle]);
  const animateCircle = useCallback(() => {
    if (!isDesktop) {
      if (containerRef.current) {
        clearCircleAnimation(containerRef.current);
      }

      return;
    }

    if (containerRef.current) {
      cirsleAnimation(containerRef.current);
    }
  }, [containerRef.current, isDesktop]);

  useEffect(() => {
    window.addEventListener('scroll', animateCircle, true);

    return () => {
      window.removeEventListener('scroll', animateCircle, true);
    };
  }, [animateCircle]);
  return containerRef;
};
