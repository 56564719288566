/* eslint-disable max-len */
import React from 'react';
import { IconProps } from 'components/Icons/types';
import { BaseIcon } from 'components/Icons/BaseIcon';

export default function Error96({
  position,
  width,
  height,
}: IconProps) {
  return (
    <BaseIcon
      sizeX={96}
      sizeY={96}
      width={width}
      height={height}
      color="none"
      position={position}
    >
      <path
        d="M92 48C92 72.2968 72.2968 92 48 92C23.7032 92 4 72.2968 4 48C4 23.7032 23.7032 4 48 4C72.2968 4 92 23.7032 92 48Z"
        fill="#D03032"
        fillOpacity="0.1"
      />
      <path
        d="M84 48C84 67.8792 67.8792 84 48 84C28.1208 84 12 67.8792 12 48C12 28.1208 28.1208 12 48 12C67.8792 12 84 28.1208 84 48Z"
        fill="#D03032"
        fillOpacity="0.1"
      />
      <path
        d="M76 48C76 63.4616 63.4616 76 48 76C32.5384 76 20 63.4616 20 48C20 32.5384 32.5384 20 48 20C63.4616 20 76 32.5384 76 48Z"
        fill="#D03032"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.3788 59.5884L47.314 31.363C47.6247 30.8451 48.3753 30.8451 48.686 31.363L65.6212 59.5884C65.9412 60.1216 65.5571 60.8 64.9352 60.8H31.0648C30.4429 60.8 30.0588 60.1216 30.3788 59.5884ZM27.6348 57.942L44.57 29.7166C46.1236 27.1273 49.8764 27.1273 51.43 29.7166L68.3652 57.942C69.9649 60.6081 68.0444 64 64.9352 64H31.0648C27.9556 64 26.0351 60.6081 27.6348 57.942ZM48 57.6C47.1164 57.6 46.4 56.8837 46.4 56V55.8C46.4 54.9164 47.1164 54.2 48 54.2C48.8837 54.2 49.6 54.9164 49.6 55.8V56C49.6 56.8837 48.8837 57.6 48 57.6ZM48 51.6C47.1164 51.6 46.4 50.8837 46.4 50V42C46.4 41.1164 47.1164 40.4 48 40.4C48.8837 40.4 49.6 41.1164 49.6 42V50C49.6 50.8837 48.8837 51.6 48 51.6Z"
        fill="#F7F9FF"
      />
    </BaseIcon>
  );
}
