import React, { useMemo } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { IconProps } from './types';

type Size = {
    width: number;
    height: number;
};

type Props = {
    icon: (props: IconProps) => JSX.Element;
    size: number | Size;
    tabletSize?: number | Size;
    mobileSize?: number | Size;
} & Omit<IconProps, 'height' | 'width'>;

const getSize = (size: number | Size) => {
  if (typeof size === 'number') {
    return {
      width: size,
      height: size,
    };
  }
  return size;
};

export const ResponsiveIcon = ({
  icon: Icon, size, tabletSize, mobileSize, ...props
}: Props) => {
  const isTabletUp = useMediaQuery((theme: any) => theme.breakpoints.up('tablet'));
  const isDesktopUp = useMediaQuery((theme: any) => theme.breakpoints.up('desktop'));

  const actualSize = useMemo(() => {
    if (isDesktopUp) {
      return getSize(size);
    }
    if (isTabletUp) {
      return getSize(tabletSize || size);
    }

    return getSize(mobileSize || tabletSize || size);
  }, [isDesktopUp, isTabletUp, mobileSize, size, tabletSize]);

  return (
    <Icon
      width={actualSize.width}
      height={actualSize.height}
      {...props}
    />
  );
};
