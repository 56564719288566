/* eslint-disable max-len */
import { AnyAction } from 'redux';

import { IBaseReducer } from '../../types/otherData';
import { getAllyApproveDisclosureAction, getAllyDisclosureAction } from './actionTypes';
import { AllyDisclosure } from '../../types/allyDisclosure';
import { RESET_APP } from '../app';

type Reducer = Omit<IBaseReducer, 'isLoaded' | 'isLoading'> & {
  allyDisclosures: {
    disclosures: Array<AllyDisclosure>,
    token: string;
  };
  allyApproveDisclosures: {
    disclosures: Array<AllyDisclosure>,
    token: string;
  };
};

const initialState: Reducer = {
  allyDisclosures: { disclosures: [], token: '' },
  allyApproveDisclosures: { disclosures: [], token: '' },
  status: 'initial',
};

export function lenderSpecificReducer(state = initialState, action: AnyAction): Reducer {
  const { type } = action;
  switch (type) {
    case getAllyDisclosureAction.REQUEST:
      return {
        ...state,
        status: 'loading',
      };
    case getAllyDisclosureAction.SUCCESS:
      return {
        ...state,
        status: 'loaded',
        allyDisclosures: action.payload.data,
      };
    case getAllyApproveDisclosureAction.SUCCESS:
      return {
        ...state,
        allyApproveDisclosures: action.payload.data,
      };
    case getAllyDisclosureAction.FAILURE:
      return {
        ...state,
        status: 'loaded',
        allyDisclosures: {
          token: '',
          disclosures: [
            {
              id: 1,
              type: 'Prequal',
              order: 1,
              contentHtml: '<p style="color: #2a2a2a;text-transform: none; font-weight: 500;font-size: 14px; font-family: lato; line-height:21px; padding:20px">This consent applies to any Synchrony Bank application information that you or we elect at any time to provide electronically including all disclosures, agreements, and other documents relating to your\r\n\t\t\t\t\tapplication. It also permits us to obtain your Electronic Signature in connection with your Synchrony Bank loan documents. To receive information electronically, you need: an internet connection, a functioning email address, a way to store these documents and software to view and print PDF files. If you need to update your email address\r\n\t\t\t\t\twithin the application process, you can do by contacting Customer Care at 1-800-427-9184.\r\n\t\t\t\t<br><br>\r\n\t\t\t\t\tTo obtain paper copies of disclosures within the application process or to withdraw your consent, at no cost to you, call us at 800-427-9184. If you withdraw your consent, you will not be able to complete the application process.\r\n\t\t\t\t<br><br>\r\n\t\t\t\t\tWe will notify you of software or hardware changes that could prevent you from accessing or retaining these records during the application process. At that time, you will have the right to withdraw your consent at no cost.\r\n\t\t\t<br><br>\r\n\t\t\t\t\tBy selecting <strong>Agree and Continue</strong> below, you acknowledge that you have received and agree to the eSign Consent &amp; Disclosure above.\r\n\t\t\t\t\t<ul>\r\n\t\t\t\t\t<li>\r\n\t\t\t\t\t\t<a href="https://stage-cdn.lending.ally.com/disclosures/ESignActDisclosure.pdf" target="_blank" >\r\n\t\t\t\t\t\tView and save the disclosure for your records.</a>\r\n\t\t\t\t\t\t</li>\r\n\t\t\t\t\t\t</ul>\r\n\t\t\t</p>',
              content: 'This consent applies to any Synchrony Bank loan origination information that you or we elect at any time to provide electronically including all disclosures, agreements, and other documents relating to your loan origination. It also permits us to obtain your Electronic Signature in connection with your Synchrony Bank loan origination documents. To receive information electronically, you need: an internet connection, a functioning email address, a way to store these documents and software to view and print PDF files. If you need to update your email address within the loan origination process, you can do by contacting Customer Care at 1-800-427-9184.\\nTo obtain paper copies of disclosures within the loan origination process or to withdraw your consent, at no cost to you, call us at 1-800-427-9184. If you withdraw your consent, you will not be able to complete the loan origination process electronically.\\nWe will notify you of software or hardware changes that could prevent you from accessing or retaining these records during the loan origination process. At that time, you will have the right to withdraw your consent at no cost\\nBy selecting Agree and Continue below, you acknowledge that you have received and agree to the eSign Consent & Disclosure above.',
            },
            {
              id: 2,
              type: 'Prequal',
              order: 2,
              contentHtml: '<a href="https://stage-cdn.lending.ally.com/disclosures/USA-Patriot-Act.pdf" target="_blank">USA PATRIOT Act (PDF)</a>',
              content: 'USA PATRIOT Act (PDF)',
            },
            {
              id: 3,
              type: 'Prequal',
              order: 3,
              contentHtml: '<a href="https://stage-cdn.lending.ally.com/disclosures/TCPA-Disclosure.pdf" target="_blank" >TCPA (PDF)</a>',
              content: 'TCPA(PDF)',
            },
            {
              id: 4,
              type: 'Prequal',
              order: 4,
              contentHtml: '<a href="https://stage-cdn.lending.ally.com/disclosures/Ally_US_Consumer_Privacy_Notice.pdf" target="_blank">Synchrony Bank Privacy Notice (PDF)</a>',
              content: 'Synchrony Bank Privacy Notice (PDF)',
            },
            {
              id: 5,
              type: 'Prequal',
              order: 5,
              contentHtml: '<a href="https://stage-cdn.lending.ally.com/disclosures/Terms_and_Conditions.pdf" target="_blank">Synchrony Bank Terms and Conditions (PDF)</a>',
              content: 'Synchrony Bank Terms and Conditions (PDF)',
            },
            {
              id: 6,
              type: 'Prequal',
              order: 6,
              contentHtml: '<p style="color: #2a2a2a;text-transform: none; font-weight: 500; font-size: 14px; font-family: lato; line-height:21px; padding:20px">\r\n\t\t\t\t\tIncome from alimony, child support or separate maintenance payments\r\n\t\t\t\t\tneed not be disclosed if you do not wish to have this income\r\n\t\t\t\t\tconsidered as a basis for repaying this obligation.\r\n\t\t\t\t\t</p>',
              content: 'Income from alimony, child support or separate maintenance payments\r\n\t\t\t\t\tneed not be disclosed if you do not wish to have this income\r\n\t\t\t\t\tconsidered as a basis for repaying this obligation.',
            },
            {
              id: 7,
              type: 'Prequal',
              order: 7,
              contentHtml: '<div style="color: #2a2a2a;text-transform: none; font-weight: 500; font-size: 14px; font-family: lato; line-height:21px; padding:20px"<p>\r\n\t\tI understand that I am providing “written instructions” to Synchrony Bank \r\n\t\tunder the Fair Credit Reporting Act authorizing Synchrony Bank to obtain \r\n\t\tinformation from my personal credit profile or other information from one or \r\n\t\tmore consumer reporting agencies. I authorize Synchrony Bank to obtain such \r\n\t\tinformation solely to prequalify me for credit options.\r\n\t\t\t\t\t</p>\r\n\t\t\t\t\t</div>',
              content: 'I understand that I am providing “written instructions” to Synchrony Bank\\r\\n\\t\\tunder the Fair Credit Reporting Act authorizing Synchrony Bank to obtain\\r\\n\\t\\tinformation from my personal credit profile or other information from one or\\r\\n\\t\\tmore consumer reporting agencies. I authorize Synchrony Bank to obtain such\\r\\n\\t\\tinformation solely to prequalify me for credit options.',
            },
          ],
        },
      };
    case RESET_APP:
      return initialState;
    default:
      return state;
  }
}
