import { AnyAction } from 'redux';
import { getCustomerAccountsActions } from './actionTypes';
import { CustomerAccountsReducer } from './types';

const initialLocationsState: CustomerAccountsReducer = {
  data: undefined,
  status: 'initial',
};

export function customerAccountsReducer(state = initialLocationsState, action: AnyAction): CustomerAccountsReducer {
  const { payload, type } = action;
  switch (type) {
    case getCustomerAccountsActions.REQUEST:
      return {
        ...state,
        status: 'loading',
        error: null,
      };
    case getCustomerAccountsActions.SUCCESS:
      return {
        ...state,
        data: payload.data,
        status: 'loaded',
      };
    case getCustomerAccountsActions.FAILURE:
      return {
        ...state,
        status: 'error',
        error: payload,
      };
    default:
      return {
        ...state,
      };
  }
}
