/* eslint-disable max-len */
import React from 'react';
import { IconProps } from 'components/Icons/types';
import { BaseIcon } from 'components/Icons/BaseIcon';

export default function ArrowLeft24({
  color = '#090224',
  position,
  width,
  height,
}: IconProps) {
  return (
    <BaseIcon
      sizeX={24}
      sizeY={24}
      width={width}
      height={height}
      color={color}
      position={position}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3157 5.18433C15.6281 5.49675 15.6281 6.00328 15.3157 6.3157L9.38137 12.25L15.3157 18.1843C15.6281 18.4967 15.6281 19.0033 15.3157 19.3157C15.0033 19.6281 14.4967 19.6281 14.1843 19.3157L7.68432 12.8157C7.3719 12.5033 7.3719 11.9967 7.68432 11.6843L14.1843 5.18433C14.4967 4.87191 15.0033 4.87191 15.3157 5.18433Z"
      />
    </BaseIcon>
  );
}
