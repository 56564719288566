import {
  getCustomerAccountsApiRequest,
} from '../apiRequests';
import { defThunkRequest } from '../../utils/defAsyncAction';
import {
  getCustomerAccountsActions,
} from './actionTypes';
import { AccountsData } from './types';

export const getCustomerAccountsThunk = defThunkRequest<{id: string}, AccountsData>({
  actionTypes: getCustomerAccountsActions,
  thunkSteps: [
    ({ data }) => getCustomerAccountsApiRequest(data.id),
  ],
});
