import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import Tablet from './Tablet';
import Mobile from './Mobile';

type Props = {
  variant?: 'responsive' | 'mobile' | 'tablet';
};

export const ApplicationLogo = ({
  variant = 'responsive',
}: Props) => {
  const isTablet = useMediaQuery((theme: any) => theme.breakpoints.up('tablet'));

  if ((isTablet && variant === 'responsive') || variant === 'tablet') {
    return <Tablet />;
  }

  return <Mobile />;
};
