import { typography } from './typography';

const MuiNewButtonOverrides = {
  root: {
    ...typography.button,
    borderRadius: 5,
    padding: 'var(--spacing-s) var(--spacing-l)',
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 30,

    '& > *': {
      pointerEvents: 'none',
    },

    '&$disabled': {
      backgroundColor: 'rgba(9, 2, 36, 0.1)',
      color: 'rgba(9, 2, 36, 0.3)',
      cursor: 'default',
      border: 0,

      '& svg': {
        fill: 'rgba(9, 2, 36, 0.3)',
      },
    },
    '& svg.left': {
      marginRight: 8,
    },
    '& svg.right': {
      marginLeft: 8,
    },
  },
  text: {
    padding: '4px var(--spacing-s)',
    textDecoration: 'underline',
    textTransform: 'none',
    letterSpacing: 'unset',
    ...(typography.subtitle1 as any),
    '& svg': {
      fill: '#342088',
    },
    '&:hover:not([disabled])': {
      color: '#2800f0',
      textDecoration: 'underline',

      '& svg': {
        fill: '#2800f0',
      },
    },
    '&$disabled': {
      backgroundColor: 'transparent',
    },
  },
  sizeSmall: {
    ...typography.overline,
    color: undefined,
    padding: '10px 12px',

    '&$disabled': {
      border: '1px solid rgba(9, 2, 36, 0.1)',
    },
  },
  textSizeSmall: {
    padding: 'var(--spacing-xs) var(--spacing-s)!important',
    '&$disabled': {
      border: '0!important',
    },
  },
  outlinedPrimary: {
    padding: 'var(--spacing-s) var(--spacing-l)',
    border: '1px solid rgba(52, 32, 136, 0.7)',
    backgroundColor: '#F7F9FF',
    '& svg': {
      fill: '#342088',
    },
    '&:hover:not([disabled])': {
      color: '#2800f0',
      borderColor: '#2800f0',
      '& svg': {
        fill: '#2800f0',
      },
    },
    '&$disabled': {
      border: 0,
    },
  },
  outlinedSizeSmall: {
    border: '1px solid rgba(9, 2, 36, 0.1)',
  },
  containedPrimary: {
    '& svg': {
      fill: 'white',
    },
    '&:hover:not([disabled])': {
      backgroundColor: '#2800f0',
    },
  },
};

export default MuiNewButtonOverrides;
