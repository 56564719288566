import { defActionTypes } from '../../utils/defActionTypes';

export const getSubmissionAction = defActionTypes('GET_SUBMISSION');
export const createSubmissionAction = defActionTypes('create_submission');
export const completeSubmissionAction = defActionTypes('complete_submission');
export const reSubmitSubmissionAction = defActionTypes('resubmit_submission');
export const updateSubmissionAction = defActionTypes('update_submission');
export const manualApplySubmissionAction = defActionTypes('manual_apply_submission');

export const goNextApplicationSubmissionAction = defActionTypes('go_next_application_submission');
export const SUBMISSION_UPDATE = 'SUBMISSION_UPDATE';
