import { ExtractRouteParams, generatePath, matchPath } from 'react-router';
import { RouteType } from '../types/route';
import { toParams } from './params';

export function defRoute<S extends string>(pattern: S): RouteType<S> {
  return {
    pattern,
    patternBuilder: (params?: Partial<ExtractRouteParams<S>>) => {
      if (!params) return pattern;
      const keys = Object.keys(params);

      let res = pattern as string;
      keys.forEach((key: string) => {
        // @ts-ignore
        const val = params[key];
        res = pattern.replace(`:${key}`, val.toString());
      });

      return res;
    },
    url: (
      params?: ExtractRouteParams<S>,
      search?: { [key: string]: number | string | boolean | undefined | Array<string> },
    ) => `${generatePath(pattern, params)}${toParams(search)}`,
    isMatch: (path, exact = true) => !!matchPath(path, {
      path: pattern,
      exact,
    }),
  };
}
