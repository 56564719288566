import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { AnyAction, Dispatch } from 'redux';
import { IThunkRequest } from '../utils/defAsyncAction';
import { ICustomHistory, useCustomHistory } from './useCustomHistory';

export const asyncDispatch = <TData, TRes>(
  action: (data: IThunkRequest<TData, TRes>) => any,
  data: TData,
  dispatch: Dispatch<AnyAction>,
  history?: ICustomHistory,
  silentError?: boolean,
) => new Promise<TRes>((res, rej) => {
  dispatch(action({
    data,
    onSuccess: ({ data: result }) => res(result),
    onFailure: rej,
    history,
    silentError,
  }));
});

export const useAsyncDispatch = () => {
  const dispatch = useDispatch();
  const history = useCustomHistory();

  return useCallback(<TData, TRes>(
    action: (data: IThunkRequest<TData, TRes>) => any,
    data: TData,
    silentError?: boolean) => {
    return asyncDispatch(
      action,
      data,
      dispatch,
      silentError ? undefined : history,
      silentError,
    );
  }, [dispatch, history]);
};
