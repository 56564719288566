import { AnyAction } from 'redux';

import { IBaseReducer, ILenderStatus, ILenderStatusExtended } from '../../types/otherData';
import { getLendersStatusAction } from './actionTypes';

type Reducer = Omit<IBaseReducer, 'isLoaded' | 'isLoading'> & {
  data: Array<ILenderStatus>;
  extendedData: Array<ILenderStatusExtended>;
  lastUpdated: number;
};

const initialState: Reducer = {
  data: [],
  extendedData: [],
  lastUpdated: 0,
};

export function lendersStatusReducer(state = initialState, action: AnyAction): Reducer {
  const { type } = action;
  switch (type) {
    case getLendersStatusAction.REQUEST:
      return {
        ...state,
        status: 'loading',
      };
    case getLendersStatusAction.SUCCESS:
      return {
        ...state,
        status: 'loaded',
        lastUpdated: Date.now(),
        data: (action.payload.data as Array<ILenderStatusExtended>)
          .filter((x) => x.lender !== 'external'
              /* clearing lender that we don't have on FE side but still have on BE */
              && (x.lender as string) !== 'alphaeon') as Array<ILenderStatus>,
        extendedData: (action.payload.data as Array<ILenderStatusExtended>)
          .filter((x) =>
            /* clearing lender that we don't have on FE side but still have on BE */
          // eslint-disable-next-line implicit-arrow-linebreak
            (x.lender as string) !== 'alphaeon'),
      };
    default:
      return state;
  }
}
