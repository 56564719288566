import React from 'react';
import { Box } from '@material-ui/core';
import { DangerousComponentInjector } from '../DangerousComponentInjector';
import { CcTypography } from '../CcTypography';
import { IconProps } from '../Icons/types';
import { ResponsiveIcon } from '../Icons/ResponsiveIcon';

type Props = {
    title?: string;
    titleIcon: (props: IconProps) => JSX.Element;
    titleParams?: any;
};

export const AlertTitle = ({
  titleParams,
  title,
  titleIcon: Icon,
}: Props) => {
  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      flexDirection="column"
      marginBottom="var(--spacing-m)"
    >
      <Box marginBottom="16px">
        <ResponsiveIcon icon={Icon} size={96} tabletSize={72} />
      </Box>
      <Box width="100%" textAlign="center">
        <DangerousComponentInjector
          component={CcTypography}
          text={title}
          fontWeight={600}
          variant="h2"
          params={titleParams}
        />
      </Box>
    </Box>
  );
};
