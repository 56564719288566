import { useCallback, useMemo, useState } from 'react';

export type ModalControlProps<T> = [
    open: boolean,
    control: {
      open: (data?: T) => void,
      close: () => void,
      data?: T
    },
];

type Props = {
  onClose?: () => void;
};

export const useModalControl = <T = unknown>(props?: Props): ModalControlProps<T> => {
  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<T>();
  const handleOpen = useCallback((modalData?: T) => {
    setOpen(true);
    if (modalData) {
      setData(modalData);
    }
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
    setData(undefined);
    if (props?.onClose) {
      props.onClose();
    }
  }, [props]);
  return useMemo(() => {
    return [open, { open: handleOpen, close: handleClose, data }];
  }, [open, handleOpen, handleClose, data]);
};
