import { apiRequest } from '../../utils/apiRequest';
import { PreSale } from '../../types/preSale';
import { toParams } from '../../utils/params';

export const getPreSalesApiRequest = (getOnlyCurrent?: boolean, count?: number) => apiRequest(
  `presale${toParams({ current: getOnlyCurrent, count })}`,
  {
    method: 'GET',
  },
);

export type CreatePreSaleBody = Pick<PreSale, 'name' | 'effectiveAt' | 'startAt' | 'endAt'>;
export const createPreSaleApiRequest = (body: CreatePreSaleBody) => apiRequest(
  'presale',
  {
    method: 'POST',
    body,
  },
);

export const disablePreSaleApiRequest = (id: string) => apiRequest(
  `presale/${id}`,
  {
    method: 'DELETE',
  },
);
