import { apiRequest } from 'utils/apiRequest';
import { toParams } from '../../utils/params';

export const getCustomerApiRequest = (id: string) => apiRequest(
  `customer/${id}`,
  {
    method: 'GET',
  },
);

export const getCustomerAccountsApiRequest = (id: string) => apiRequest(
  `customer/${id}/accounts`,
  {
    method: 'GET',
  },
);

export type AssignCustomerPayload = {
    location: string;
    submission: string;
    customer: string;
};

export const assignCustomerApiRequest = ({
  submission,
  location,
  customer,
}: AssignCustomerPayload) => apiRequest(
  `submission/${submission}/customer${toParams({ location })}`,
  {
    method: 'POST',
    body: {
      customerId: customer,
    },
  },
);
