import { AnyAction } from 'redux';
import { getListOfLocationsLendersAction } from './actionTypes';
import { IAccountLookupLocations } from '../../types/accountLookup';
import { IBaseReducer } from '../../types/otherData';

interface LookupState extends IBaseReducer{
  data?: IAccountLookupLocations;
}

const initialState: LookupState = {
  isLoading: false,
  status: 'initial',
  data: undefined,
};

export function accountLookupReducer(
  state = initialState,
  action: AnyAction,
): LookupState {
  const { type, payload } = action;
  switch (type) {
    case getListOfLocationsLendersAction.REQUEST:
      return {
        ...state,
        data: undefined,
        isLoading: true,
        status: 'loading',
      };
    case getListOfLocationsLendersAction.SUCCESS:
      return {
        ...state,
        data: payload.data,
        isLoading: false,
        status: 'loaded',
      };
    case getListOfLocationsLendersAction.FAILURE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
