export const GUEST_MODE_BASE = '/al';
export const PUBLIC_MODE_BASE = '/pl';
export const IMPORT_MODE_BASE = '/import';
export const GENERAL_MODE_BASE = '/';

type ApplicationFlows = 'general' | 'guest' | 'public' | 'import';

export const applicationFlowMode: ApplicationFlows = (() => {
  if (window.location.pathname.startsWith(GUEST_MODE_BASE)) return 'guest';
  if (window.location.pathname.startsWith(PUBLIC_MODE_BASE)) return 'public';
  if (window.location.pathname.startsWith(IMPORT_MODE_BASE)) return 'import';
  return 'general';
})();

export const getBaseHistoryUrl = () => {
  if (applicationFlowMode === 'guest') return GUEST_MODE_BASE;
  if (applicationFlowMode === 'public') return PUBLIC_MODE_BASE;
  if (applicationFlowMode === 'import') return IMPORT_MODE_BASE;
  return GENERAL_MODE_BASE;
};
