import { AnyAction } from 'redux';

import {
  clearApplicationsList,
  getApplicationsAction,
  updateSubmissionCurrentLender,
  updateSubmissionListItem,
} from './actionTypes';
import { IAdminApplicationsReducer } from './types';
import { ISubmission } from '../../types/submissions';
import { getSubmissionAction } from '../submission';
import { ELEMENTS_PER_PAGE } from '../../applicationConstants';

const initialApplicationsState: IAdminApplicationsReducer = {
  data: {
    items: [],
    hasNext: false,
    offset: 0,
  },
  isLoading: false,
  error: null,
};

export function adminApplicationsReducer(
  state = initialApplicationsState,
  action: AnyAction,
): IAdminApplicationsReducer {
  const { payload, type } = action;
  switch (type) {
    case getApplicationsAction.REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case getApplicationsAction.SUCCESS: {
      const hasNext = payload.data.items.length > ELEMENTS_PER_PAGE;
      let { items } = payload.data;
      if (hasNext) {
        items = items.slice(0, ELEMENTS_PER_PAGE);
      }
      return {
        ...state,
        data: {
          ...payload.data,
          items,
          hasNext,
        },
        isLoading: false,
      };
    }
    case getSubmissionAction.SUCCESS:
    case updateSubmissionListItem.TYPE:
      if (state.data.items.length === 0) return state;
      return {
        ...state,
        data: {
          ...state.data,
          items: state
            .data
            ?.items
            .map((item: ISubmission) => (item.id === payload.data.id ? payload.data : item)),
        },
        isLoading: false,
      };
    case updateSubmissionCurrentLender.TYPE:
      if (state.data.items.length === 0) return state;
      return {
        ...state,
        data: {
          ...state.data,
          items: state
            .data
            ?.items
            .map((item: ISubmission) => (item.id === payload.data.submission
              ? {
                ...item,
                currentLenderApplication: payload.data,
              }
              : item)),
        },
        isLoading: false,
      };
    case getApplicationsAction.FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case clearApplicationsList.TYPE:
      return initialApplicationsState;
    default:
      return {
        ...state,
      };
  }
}
