/* eslint-disable max-len */
import React from 'react';
import { IconProps } from 'components/Icons/types';
import { BaseIcon } from 'components/Icons/BaseIcon';

export default function ColorAlarm24({
  color = '#D03032',
  width,
  height,
  position,
}: IconProps) {
  return (
    <BaseIcon
      sizeX={24}
      sizeY={24}
      width={width}
      height={height}
      color={color}
      position={position}
    >
      <path
        d="M22.8772 20.3619L12.793 3.41268C12.7059 3.28572 12.588 3.18164 12.4496 3.10965C12.3113 3.03766 12.1569 3 12 3C11.8431 3 11.6887 3.03766 11.5504 3.10965C11.412 3.18164 11.2941 3.28572 11.207 3.41268L1.12282 20.3619C1.0424 20.4974 1.00004 20.6512 1 20.8077C0.999959 20.9642 1.04224 21.118 1.12259 21.2536C1.20294 21.3892 1.31853 21.5018 1.45776 21.5801C1.59699 21.6585 1.75496 21.6998 1.91581 21.7H22.0842C22.245 21.6998 22.403 21.6585 22.5422 21.5801C22.6815 21.5018 22.7971 21.3892 22.8774 21.2536C22.9578 21.118 23 20.9642 23 20.8077C23 20.6512 22.9576 20.4974 22.8772 20.3619ZM13.1056 9.2111L12.672 15.0987H11.328L10.9192 9.2111H13.1056ZM12 19.0238C11.734 19.0238 11.474 18.9471 11.2528 18.8033C11.0317 18.6595 10.8593 18.4551 10.7575 18.216C10.6557 17.9768 10.6291 17.7137 10.681 17.4598C10.7329 17.206 10.861 16.9728 11.049 16.7898C11.2371 16.6068 11.4768 16.4821 11.7376 16.4316C11.9985 16.3811 12.2689 16.4071 12.5147 16.5061C12.7604 16.6052 12.9704 16.7729 13.1182 16.9881C13.266 17.2033 13.3449 17.4563 13.3449 17.7151C13.3449 18.0622 13.2032 18.3951 12.951 18.6405C12.6988 18.8859 12.3567 19.0238 12 19.0238Z"
      />
    </BaseIcon>
  );
}
