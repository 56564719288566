/* eslint-disable max-len */
import React from 'react';
import { IconProps } from 'components/Icons/types';
import { BaseIcon } from 'components/Icons/BaseIcon';

export default function Mobile({
  position,
}: IconProps) {
  return (
    <BaseIcon
      sizeX={24}
      sizeY={24}
      color="none"
      position={position}
    >
      <rect y="8.23188" width="24" height="7.53623" fill="#4803FF" />
      <path
        d="M0.000370026 15.7681V8.18345C4.5004 8.18345 8.1484 4.51959 8.1484 -1.90735e-06H15.7002C15.7002 8.70849 8.67116 15.7681 0.000370026 15.7681Z"
        fill="url(#paint0_linear_1986_1289)"
      />
      <path
        d="M24.0002 8.23188V15.8165C19.5002 15.8165 15.8522 19.4804 15.8522 24H8.30035C8.30035 15.2915 15.3294 8.23188 24.0002 8.23188Z"
        fill="url(#paint1_linear_1986_1289)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1986_1289"
          x1="5.74339"
          y1="-14.1664"
          x2="-14.1255"
          y2="5.74769"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD800" />
          <stop offset="1" stopColor="#00FF80" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1986_1289"
          x1="17.6439"
          y1="5.41118"
          x2="5.99746"
          y2="18.1287"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FD00C3" />
          <stop offset="1" stopColor="#A101FF" />
        </linearGradient>
      </defs>
    </BaseIcon>
  );
}
