import { AnyAction } from 'redux';

export interface ISimpleActionType<T = unknown> {
  TYPE: string;
  execute: (payload?: T) => AnyAction;
}

type DefaultAction = <T = unknown>(type: string) => ISimpleActionType<T>;

export const defAction: DefaultAction = (type: string) => {
  return {
    TYPE: type.toUpperCase(),
    execute: (payload) => ({
      type: type.toUpperCase(),
      payload: {
        data: payload,
      },
    }),
  };
};
